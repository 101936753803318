import { graphql } from 'gatsby';
import ENSPage from '../views/ens';

export default ENSPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    startups: allContentfulEnsLoga(filter: {node_locale: {eq: "pl-PL"}}) {
      edges {
        node {
          alt
          link
          logo {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;
