import Logo from '../../images/header/dark_logo.png';

export const theme = {
  background: '#ffffffff',
  nav: {
    logo: Logo
  },
  colors: {
    uiBlack: '#080E14',
    uiBlue: '#5FA5C5',
    uiDarkBlue: '#0F5575',
    uiLightBlue: '#6EBDE9',
    uiLightYellow: '#F7F7EC',
    uiWhite: '#F5FAFD',
    uiPaleBlue: '#C4DFEC',
    bg: {
      secondary: '#F5FAFD'
    }
  },
  typography: {
    headers: {
      h1: 'bold 48px auto Montserrat',
      h2: 'bold 36px auto Montserrat',
      h3: 'bold 24px auto Montserrat',
      h4: 'bold 18px auto Montserrat'
    },
    body: {
      m: 'regular 18px 150% Raleway',
      s: 'semibold 16px 150% Raleway',
      xs: 'semibold 14px 150% Raleway'
    },
    quote: {
      m: 'light italic 28px 48px Raleway',
      s: 'light italic 24px 48px Raleway'
    },
    btn: 'semibold 14px 150% Montserrat'
  },
  buttons: {
    fill: {
      bg: '#6ebde9',
      text: 'rgba(255, 255, 255, 90)'
    },
    hollow: {
      color: '#0F5575',
      hover: 'rgba(255, 255, 255, 90)'
    },
    link: {
      color: '#0F5575'
    }
  }
};
