import styled from "styled-components";
import { respondTo } from "../../utils/breakpoints";
import { PageSection } from "../../components/global/flexbox";
import CircleStartup from "./circles-dark-left.svg"
import CircleStartup2 from "./circles-light-right.svg"
import CircleStartup3 from "./circles-light-left.png"
import PartnersCircle from "./circles-dark-right-partners.png"

export const LogoGrid = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px 32px;
`

export const EMSImg = styled.img`
  width: 100%;
`

export const LogoCard = styled.a`
  border-radius: 16px;
  width: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid var(--base, #5FA5C5);
  background: #FFF;
  &:hover {
    background: rgba(95, 165, 197, 0.05);
  }
`;

export const LogoImage = styled.img`
  width: 140px;
  height: auto;
  aspect-ratio: 1/1;
`

export const PatronLogo = styled.img`
  width: 250px;
  &.small {
    width: 200px;
  }
  &.full {
    width: 100%;
    ${respondTo.m`
      width: 900px; 
    `}
  }
  height: auto;
  ${respondTo.xs`
    align-self: center;
  `}
`

export const ENSCardTitle = styled.h4`
  color: var(--mid, #0F5575);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
`

export const ENSCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 32px;
  background: #FFF;
  display: flex;
  width: 90%;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  ${respondTo.m`
    width: 320px;
    margin: 0;
  `}
`

export const ENSCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${respondTo.m`
    flex-direction: row;
  `}
`

export const CodeCard = styled.div`
  align-self: center;
  padding: 10px 24px;
  gap: 10px;
  font-size: bold;
  border-radius: 32px;
  background: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  > strong {
    color: #5FA5C5;
  }
`

export const StartupSection = styled(PageSection)`
  background: url(${CircleStartup}), url(${CircleStartup2}), url(${CircleStartup3});
  background-repeat: no-repeat;
  background-position: 
    top left, 
    center right, 
    bottom left;
`

export const ParnterSection = styled(PageSection)`
  background: url(${PartnersCircle});
  background-repeat: no-repeat;
  background-position: top right;
`