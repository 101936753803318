import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ThemeProvider } from 'styled-components';
import { theme } from '../../themes/default';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import {
  Col,
  GradientWrapper,
  InnerColumn40,
  InnerColumn50,
  InnerColumn60,
  Row,
  StyledPageSection
} from '../../components/sections/styled.components';

import GrowthSVG from './growth.svg';
import InvestorsSVG from './investors.svg';
import StaffSVG from './staff.png';
import CirclesHero from './circles.svg';

import ENSBg from '../../images/general/ens-background.jpeg';
import GroupImage from '../../images/ens/grafika_grupy.png';
import MapImage from '../../images/ens/grafika_mapa.png';
import ExpandImage from '../../images/ens/grafika_expand.png';
import Krzysiu from '../../images/ens/krzysiu.png';
import {
  Header2,
  Header3,
  PageHeader,
  Text
} from '../../components/typography';
import {
  PageSection,
  PageWrapper,
  SectionWrapper
} from '../../components/global/flexbox';
import {
  CodeCard,
  EMSImg,
  ENSCard,
  ENSCardContainer,
  ENSCardTitle,
  LogoCard,
  LogoGrid,
  LogoImage,
  ParnterSection,
  PatronLogo,
  StartupSection
} from './styled.components';
import { StyledHref } from '../../components/global/buttons/styled.components';
import NIWENG from '../../images/logos/niw_eng.png';
import NIW from './ensniw.png';
import PL from './godlo.png';
import PARPENG from '../../images/logos/parp_eng.png';
import PARP from '../../images/logos/parp.png';
import KIG from '../../images/logos/kig.png';
import KIGENG from '../../images/logos/kig_eng.png';
import ENS from '../../images/logos/ens.png';
import VV from '../../images/logos/violet_vent.png';

function ENSPage({ data }) {
  const startups = data.startups.edges;
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  return (
    <ThemeProvider theme={theme}>
      <Layout withHeader={false}>
        <Seo title={t('ens-title')} description={t('ens-description')} />
        <PageWrapper>
          <StyledPageSection gradient_bg_img={ENSBg} bubbles_bg={CirclesHero}>
            <GradientWrapper>
              <InnerColumn60>
                <PageHeader>{t('ens-hero')}</PageHeader>
                <Text className="white big">{t('ens-hero-sub')}</Text>
                <Row style={{ paddingTop: '12px' }}>
                  <StyledHref href="#investing" className="white wide">
                    {t('ens-explore')}
                  </StyledHref>
                  <StyledHref href="#brochure" className="outlined">
                    {t('ens-download-brochure')}
                  </StyledHref>
                </Row>
              </InnerColumn60>
              <InnerColumn40></InnerColumn40>
            </GradientWrapper>
          </StyledPageSection>
          <PageSection className="ens">
            <SectionWrapper>
              <Row>
                <InnerColumn50>
                  <Trans
                    i18nKey="ens-connecting-poland-and-saudi"
                    parent={Header2}
                  />
                  <Text>{t('ens-connecting-content')}</Text>
                </InnerColumn50>
                <InnerColumn50>
                  <EMSImg src={GroupImage} alt="" />
                </InnerColumn50>
              </Row>
            </SectionWrapper>
          </PageSection>
          <PageSection
            className="ens"
            style={{ marginTop: '3rem', paddingBottom: '3rem' }}
            id="investing"
            bg_col="#F5FAFD">
            <SectionWrapper>
              <Col>
                <Header2>{t('ens-invest-in-poland')}</Header2>
                <ENSCardContainer>
                  <ENSCard>
                    <img src={GrowthSVG} />
                    <ENSCardTitle>{t('ens-stable-growth')}</ENSCardTitle>
                    <Text>{t('ens-stable-growth-content')}</Text>
                  </ENSCard>
                  <ENSCard>
                    <img src={InvestorsSVG} />
                    <ENSCardTitle>{t('ens-stable-investors')}</ENSCardTitle>
                    <Text>{t('ens-stable-investors-content')}</Text>
                  </ENSCard>
                  <ENSCard>
                    <img src={StaffSVG} />
                    <ENSCardTitle>{t('ens-stable-talents')}</ENSCardTitle>
                    <Text>{t('ens-stable-talents-content')}</Text>
                  </ENSCard>
                </ENSCardContainer>
              </Col>
            </SectionWrapper>
          </PageSection>
          <PageSection className="ens">
            <SectionWrapper>
              <Row>
                <InnerColumn50>
                  <Trans i18nKey="ens-5000-companies" parent={Header2} />
                  <Text>{t('ens-5000-content')}</Text>
                </InnerColumn50>
                <InnerColumn50>
                  <EMSImg src={MapImage} />
                </InnerColumn50>
              </Row>
            </SectionWrapper>
          </PageSection>
          <PageSection
            className="ens"
            style={{ marginTop: '3rem', paddingBottom: '3rem' }}
            bg_col="#F5FAFD">
            <SectionWrapper>
              <Row>
                <InnerColumn50>
                  <EMSImg src={ExpandImage} />
                </InnerColumn50>
                <InnerColumn50>
                  <Header2>{t('ens-gitex-global')}</Header2>
                  <Text>{t('ens-gitex-content')}</Text>
                  <Trans i18nKey={t('ens-code-card')} parent={CodeCard} />
                  <StyledHref
                    target="_blank"
                    href="https://www.expandnorthstar.com/">
                    {t('ens-explore-more')}
                  </StyledHref>
                </InnerColumn50>
              </Row>
            </SectionWrapper>
          </PageSection>
          <StartupSection className="ens">
            <SectionWrapper>
              <Col>
                <Header2>{t('ens-unique-startups')}</Header2>
                <Text>{t('ens-unique-content')}</Text>
              </Col>
              <LogoGrid>
                {startups.map((startup) => {
                  return (
                    <LogoCard
                      key="index"
                      href={startup?.node?.link}
                      target="_blank">
                      <LogoImage
                        alt={startup?.node?.alt}
                        src={String('https:' + startup?.node?.logo?.file?.url)}
                      />
                    </LogoCard>
                  );
                })}
              </LogoGrid>
            </SectionWrapper>
          </StartupSection>
          <PageSection
            id="brochure"
            style={{ marginTop: '3rem' }}
            bg_col="#0F5575">
            <SectionWrapper>
              <Row>
                <InnerColumn50>
                  <Header2 className="white">{t('ens-brochure')}</Header2>
                  <Text className="white">{t('ens-brochure-content')}</Text>
                  <Row style={{ paddingTop: '12px' }}>
                    <StyledHref
                      href="https://www.innovationshub.pl/ExploreStartupHubinEuropePOLAND_ENG.pdf"
                      className="white">
                      {t('ens-brochure-eng')}
                    </StyledHref>
                    <StyledHref
                      href="https://www.innovationshub.pl/ExploreStartupHubinEuropePOLAND_ARAB.pdf"
                      className="outlined">
                      {t('ens-brochure-uae')}
                    </StyledHref>
                  </Row>
                </InnerColumn50>
                <InnerColumn50>
                  <EMSImg src={Krzysiu} />
                </InnerColumn50>
              </Row>
            </SectionWrapper>
          </PageSection>
          <ParnterSection className="ens">
            <SectionWrapper>
              <Col>
                <Header2>{t('ens-partners')}</Header2>
                <Row>
                  <Col>
                    <Header3>{t('ens-patrons')}</Header3>
                    <Row>
                      <PatronLogo src={currentLang === 'pl' ? KIG : KIGENG} />
                      <PatronLogo src={currentLang === 'pl' ? PARP : PARPENG} />
                    </Row>
                  </Col>
                  <Col>
                    <Header3>{t('ens-partners-eco')}</Header3>
                    <Row>
                      <PatronLogo src={ENS} alt="ENS Logo" />
                    </Row>
                  </Col>
                  <Col>
                    <Header3>{t('ens-partner')}</Header3>
                    <Row>
                      <PatronLogo src={VV} alt="Violet Ventures Logo" />
                    </Row>
                  </Col>
                </Row>
                <Header3>{t('ens-financing')}</Header3>
                <Col className="align-start">
                  <div>
                    <Trans t={t} i18nKey={'ens-public-task'} />
                  </div>
                  <div>
                    <Trans t={t} i18nKey={'ens-funding-amount'} />
                    <br />
                    <Trans t={t} i18nKey={'ens-summary'} />
                  </div>
                  <div>
                    <Trans t={t} i18nKey={'ens-task-description'} />
                  </div>
                </Col>
                <Row>
                  <PatronLogo src={PL} />
                  <PatronLogo
                    className="full"
                    src={currentLang === 'pl' ? NIW : NIWENG}
                  />
                </Row>
              </Col>
            </SectionWrapper>
          </ParnterSection>
        </PageWrapper>
      </Layout>
    </ThemeProvider>
  );
}

export default ENSPage;
